// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("/opt/build/repo/src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-apps-fido-js": () => import("/opt/build/repo/src/pages/apps/fido.js" /* webpackChunkName: "component---src-pages-apps-fido-js" */),
  "component---src-pages-apps-gmf-js": () => import("/opt/build/repo/src/pages/apps/gmf.js" /* webpackChunkName: "component---src-pages-apps-gmf-js" */),
  "component---src-pages-apps-pupusas-js": () => import("/opt/build/repo/src/pages/apps/pupusas.js" /* webpackChunkName: "component---src-pages-apps-pupusas-js" */),
  "component---src-pages-apps-santarosa-js": () => import("/opt/build/repo/src/pages/apps/santarosa.js" /* webpackChunkName: "component---src-pages-apps-santarosa-js" */),
  "component---src-pages-apps-volant-js": () => import("/opt/build/repo/src/pages/apps/volant.js" /* webpackChunkName: "component---src-pages-apps-volant-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-websites-js": () => import("/opt/build/repo/src/pages/websites.js" /* webpackChunkName: "component---src-pages-websites-js" */),
  "component---src-pages-websites-evet-js": () => import("/opt/build/repo/src/pages/websites/evet.js" /* webpackChunkName: "component---src-pages-websites-evet-js" */),
  "component---src-pages-websites-inxights-js": () => import("/opt/build/repo/src/pages/websites/inxights.js" /* webpackChunkName: "component---src-pages-websites-inxights-js" */),
  "component---src-pages-websites-jwh-js": () => import("/opt/build/repo/src/pages/websites/jwh.js" /* webpackChunkName: "component---src-pages-websites-jwh-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

